import tw, { styled } from 'twin.macro';

import React from 'react';
import { Link } from 'gatsby';
import { PortableText } from '../elements';
import SanityImage from '../global/SanityImage';

import { Heading, Button, Container } from '../atoms';

const Content = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  a,
  li {
    ${tw`!text-white`}
  }
`;

const HomeHero = ({ title, image, description, fluid, to, target }) => {
  function linkSlug() {
    if (target._type) {
      if (target._type === 'pages') return `/${target.slug.current}`;
      if (target._type === 'posts') return `/blog/${target.slug.current}`;
      if (target._type === 'collection')
        return `/collection/${target.slug.current}`;
      if (target._type === 'category')
        return `/category/${target.slug.current}`;
    }
    return null;
  }

  return (
    <div tw="bg-green">
      <Container width="full" tw="text-white flex items-center">
        <div tw="container mx-auto flex flex-wrap items-center">
          <div tw="w-full md:w-5/12 text-center md:text-left p-4">
            <div tw="sm:text-center lg:text-left">
              <Heading>{title}</Heading>
              {description && (
                <Content>
                  <PortableText tw="!text-white font-light pb-16 pt-4 md:pt-8 md:pr-16">
                    {description}
                  </PortableText>
                </Content>
              )}
              {target && (
                <Link to={linkSlug()}>
                  <Button variant="secondary">EXPLORE THE RANGE</Button>
                </Link>
              )}
              {to && (
                <Link to={`${to || '/'}`}>
                  <Button variant="secondary">EXPLORE THE RANGE</Button>
                </Link>
              )}
            </div>
          </div>
          <div tw="w-full md:w-7/12 text-center p-4">
            {image && <SanityImage gatsbyImageId={image.asset?.id} />}
            {fluid && <SanityImage fluid={fluid} />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeHero;
